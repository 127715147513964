.navbarr {
    display: flex;
    justify-content: center;
 background: rgb(0,30,36);
background: linear-gradient(90deg, rgba(0,30,36,1) 0%, rgba(9,9,121,0.43601190476190477) 35%, rgba(0,7,255,1) 100%);
    
   
  }
  
  .nav2-links {
    display: flex;
    list-style: none;
    gap: 20px;
    margin: 0;
    
  }
  
  .nav2-links li a {
    color: rgb(7, 1, 1);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bolder;
 
  }
  
  .nav2-links li a:hover {
    text-decoration: underline;
 color: rgb(247, 248, 247);
  }