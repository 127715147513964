.header {
  background-image: url(../header.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
  position: relative;
  color: white;
  transition: background-color 0.3s ease-in-out;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-left: 20px; /* Cambia a un margen más pequeño para PCs */
}

.logo {
  height: 60px;
  margin-right: 15px;
}

.navbar {
  display: flex;
  margin-right: 20px; /* Ajusta el margen para PC */
}

.nav-links {
  display: flex;
  list-style: none;
  gap: 20px;
  margin: 0;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.nav-links li a:hover {
  text-decoration: underline;
}

.menu-toggle {
  display: none; /* Ocultar por defecto en pantallas grandes */
  flex-direction: column;
  cursor: pointer;
}

.bar {
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 4px;
  transition: all 0.3s ease;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .menu-toggle {
      display: flex; /* Mostrar el menú hamburguesa en pantallas pequeñas */
  }

  .nav-links {
      display: none; /* Ocultar menú por defecto */
      flex-direction: column; /* Cambiar a columna */
      position: absolute; /* Posicionar el menú sobre el contenido */
      top: 60px; /* Ajusta según la altura de tu top-bar */
      left: 0;
      background-color: rgba(0, 0, 0, 0.9); /* Fondo oscuro */
      width: 100%; /* Asegúrate de que ocupe el ancho completo */
      z-index: 10; /* Mantener por encima de otros elementos */
  }

  .nav-links.active {
      display: flex; /* Mostrar menú cuando esté activo */
  }
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.header-text h1 {
  font-size: 4rem;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  font-style: italic;
}

.domain {
  font-size: 20px;
  font-family: cursive;
  text-shadow: 2px 2px 5px rgba(0, 10, 2, 0.5);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style: italic;
}

span {
  font-size: 3rem;
  font-weight: bold;
  font-family: cursive;
  text-shadow: 2px 2px 5px rgba(224, 9, 9, 0.5);
  box-shadow: 2px 2px 5px rgba(94, 160, 221, 0.5);
  font-style: inherit;
}

.header-text p {
  font-size: 2rem;
  margin-top: 10px;
}

/* Cambiar el color del header al hacer scroll */
.header.scrolled .top-bar {
  background-color: rgba(0, 0, 0, 0.9); /* Fondo oscuro con transparencia al hacer scroll */
}
