.App {
  text-align: center;
}

header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.blog-post {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 20px 0;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.news-section {
  margin: 20px 0;
}

.news-item {
  padding: 10px;
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #f9f9f9;
}

footer {
  padding: 10px;
  background-color: #282c34;
  color: white;
  position: relative;
  bottom: 0;
  width: 100%;
}
