.news-section {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .news-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Espacio entre las tarjetas */
    justify-content: center;
  }
  
  .news-item {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 250px; /* Ancho reducido */
    transition: transform 0.2s;
  }
  
  .news-item:hover {
    transform: scale(1.05);
  }
  
  .news-image {
    width: 100%;
    height: 150px; /* Altura reducida */
    object-fit: cover;
  }
  
  .news-item h3 {
    font-size: 16px; /* Tamaño de fuente reducido */
    margin: 10px; /* Margen reducido */
  }
  
  .news-item p {
    font-size: 12px; /* Tamaño de fuente reducido */
    margin: 0 10px 10px; /* Margen reducido */
    color: #555;
  }
  
  .read-more {
    display: inline-block;
    margin: 10px;
    padding: 8px; /* Padding reducido */
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 12px; /* Tamaño de fuente reducido */
  }
  
  .view-more {
    display: block; /* Asegúrate de que el botón se comporte como un bloque */
    margin: 20px auto; /* Centra el botón horizontalmente */
    padding: 10px 20px;
    background-color: #007BFF; /* Color del botón */
    color: white; /* Color del texto */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center; /* Alinea el texto del botón */
    font-size: 16px;
  }
  
  .view-more:hover {
    background-color: #1138e6; /* Color de fondo al pasar el mouse */
  }
  
  